export const environment = {
    PRODUCTION: false,
    API_URL: 'https://pharma-partner-524clo5qtq-rj.a.run.app',
    AUTH0: {
        DOMAIN: 'dev-e6kqvvjgoc3fns44.us.auth0.com',
        CLIENT_ID: 'DxKrUsovNxD9rSy1iRnvcVfYwV54NdTc'
    },
    SUPERSET: {
        DOMAIN: 'https://superset-dev.rmfarma.io/'
    }
};